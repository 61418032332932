import React from "react"
import Article from "./article"

import styles from "./articles-list.module.css"

class ArticlesList extends React.Component {
  render() {
    const { nodes } = this.props
    return (
      <div className={styles.articles_list}>
        {nodes.map(({ node }) => {
          return (
          <Article
            key={node.fields.slug}
            title={node.frontmatter.title}
            slug={node.fields.slug}
            date={node.frontmatter.date}
            excerpt={node.excerpt}
            description={node.frontmatter.description}
          />
          )
        })}
      </div>
    )
  }
}

export default ArticlesList
