import React from "react"
import styles from "./title-section.module.css"


export default ({ title }) => (
  <h1 className={styles.title}>
    <span className={styles.title_hash}>#</span>
    {title}
  </h1>
)
