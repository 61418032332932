import React from "react"
import { Link } from "gatsby"
import ArticleTags from "./article-tags"

import styles from "./article.module.css"

class Article extends React.Component {
  render() {
    const { slug, title, date, update, excerpt, description, tags, html } = this.props
    const update_text = update ? ` (updated at: ${update})` : null

    return (
      <article className={styles.article_card}>
        <div className={styles.article_content}>
          <header>
            <small className={styles.date}>{date}{update_text}</small>
            <h1>
              <Link to={slug}>
                <span className={styles.title_hash}>#</span>
                {title}
              </Link>
            </h1>
            <ArticleTags tags={tags} />
          </header>
          <section className={styles.content}>
            <p
              dangerouslySetInnerHTML={{
                __html: html || description || excerpt,
              }}
            />
          </section>
        </div>
      </article>
    )
  }
}

export default Article
