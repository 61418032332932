import React from 'react'
import { Link } from 'gatsby'
import { kebabCase } from 'lodash'

import styles from './article-tags.module.css'

class ArticleTags extends React.Component {
  render() {
    const { tags } = this.props

    const Tag = props => (
      <Link className={styles.tag} to={`/snippets/topic/${kebabCase(props.tag)}/`}>
        {props.tag}
      </Link>
    )

    return (
      <div className={styles.tags}>
        {!!tags && tags.map(tag => {
          return <Tag key={tag} tag={tag} />
        })}
      </div>
    )
  }
}

export default ArticleTags
